import * as React from "react"
import { cn } from "@/lib/cn"
import { motion } from "framer-motion"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> { }

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-10 w-full rounded-md border border-input bg-white px-3 py-2 text-xl ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"

export { Input }

export interface FloatingInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  nudge?: 'up' | 'down' | '';
  nudgeCount?: number;
  textSize: string;
  invalid?: boolean;
}


const FloatingInput = React.forwardRef<HTMLInputElement, FloatingInputProps>(
  ({ className, textSize, type, nudge: bump, nudgeCount, invalid, ...props }, ref) => {
    return (
      <div className="relative w-full">
        <div
          className={cn(
            "w-full h-14 rounded-lg border border-primary bg-background shadow-sm",
            className
          )}
        />
        <motion.div
          key={nudgeCount}
          className="absolute inset-0 flex items-center px-4"
          animate={
            bump === 'up'
              ? { y: -4 }
              : bump === 'down'
                ? { y: 4 }
                : { y: 0 }
          }
          transition={{
            type: "spring",
            damping: 40,
            stiffness: 1200,
            duration: 0.06
          }}
        >
          <input
            type={type}
            className={
              `w-full h-full bg-transparent border-none outline-none text-${textSize} 
              ${invalid === true ? 'text-destructive' : 'text-primary'}
              `}
            ref={ref}
            {...props}
          />
        </motion.div>
      </div>
    )
  }
)
FloatingInput.displayName = "FloatingInput"

export { FloatingInput }